import { Alert, Button } from 'easyship-components';
import Cookies from 'js-cookie';
import { useEffect, useRef, useState } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import { SubmitHandler, useForm } from 'react-hook-form';
import { Link, useLocation, useNavigate } from 'react-router-dom';

import { ControlledInput } from '@/components/ControlledInput';
import { toastError } from '@/components/Toastify';
import { SIGNUP_LOGIN_EMAIL_REGEX } from '@/core/regex';
import useRecaptcha from '@/hooks/useRecaptcha';
import useLoginMutation from '@/pages/auth/login/hooks/useLoginMutation';
import { LoginErrorCode, LoginFormData, LoginPayload } from '@/pages/auth/login/types';
import { AuthError } from '@/pages/auth/types';
import { parse } from '@/pages/auth/utils/errorParser';
import { LocalStorageExpiry } from '@/pages/auth/utils/LocalStorageExpiry';

const IS_DEVELOPMENT = import.meta.env.VITE_ENV_NAME !== 'production';

export const LoginForm = () => {
  const localStorage = new LocalStorageExpiry();

  const credentialsEnv = import.meta.env.VITE_APP_CREDENTIALS;

  const {
    recaptchaToken: recapthcaTokenV3,
    isFetching: isFetchingRecaptcha,
    fetchAndSetRecaptcha,
  } = useRecaptcha();

  const [recaptchaTokenV2, setRecaptchaTokenV2] = useState<string | null>(null);
  const recaptchaRef = useRef<ReCAPTCHA>(null);

  const { control, handleSubmit, getValues } = useForm<LoginFormData>();

  const { mutateAsync: loginMutateAsync, isPending: isLoading } = useLoginMutation();

  const navigate = useNavigate();
  const { state } = useLocation();

  const [error, setError] = useState<AuthError | null>(() => {
    return state?.error ? state.error : null;
  });

  useEffect(() => {
    const handleUnload = () => {
      setError(null);
      window.history.replaceState({}, '');
    };
    window.addEventListener('beforeunload', handleUnload);
    return () => {
      window.removeEventListener('beforeunload', handleUnload);
    };
  }, []);

  const handleError = (data: AuthError) => {
    // make sure that the recaptcha is fetched
    fetchAndSetRecaptcha();
    if (data.code) {
      switch (data.code) {
        case LoginErrorCode.MFA_REQUIRED: {
          const email = getValues('email');
          // set to localStorage to prevent <ResendOtpButton /> sending request to BE
          if (data.mfa?.emailOtp) {
            localStorage.set('resendAllowedAt', data.mfa.emailOtp.resendAllowedAt);
            localStorage.set('expiresAt', data.mfa.emailOtp.expiresAt);
            Cookies.set('mfaToken', data.mfa.token, {
              domain: window.location.hostname === 'localhost' ? 'localhost' : '.easyship.com',
              secure: true,
              sameSite: 'none',
              partitioned: true,
              path: '/',
            });
          }
          navigate('/auth/authenticate', {
            state: {
              email: maskEmail(email),
              mfaToken: data.mfa?.token,
              expiresAt: data.mfa?.emailOtp.expiresAt,
              resendAllowedAt: data.mfa?.emailOtp.resendAllowedAt,
            },
          });
          break;
        }
        case LoginErrorCode.INVALID_V2_TOKEN: {
          const error = {
            ...data,
            message:
              '"Unable to verify your request. Please try verifying the recaptcha again and if the issue persists, contact support at support@easyship.com."',
          };
          if (recaptchaRef.current) {
            recaptchaRef.current.reset();
            setRecaptchaTokenV2(null);
          }
          setError(error);
          break;
        }
        case LoginErrorCode.INVALID_REQUEST: {
          // need to have customized message since the message passed from BE is default message
          const error = {
            ...data,
            message: 'The request body is malformed or not valid. Please try again',
          };
          setError(error);
          break;
        }
        case LoginErrorCode.INVALID_CREDENTIALS:
        case LoginErrorCode.FAILED:
        case LoginErrorCode.LOCKED_ACCOUNT:
        case LoginErrorCode.INVALID_V3_TOKEN: {
          setError(data);
          break;
        }
        default:
          toastError();
      }
    } else {
      toastError();
    }
  };

  const onSubmit: SubmitHandler<LoginFormData> = async ({ email, password }: LoginFormData) => {
    try {
      const token = await fetchAndSetRecaptcha();

      // prioritize the fetched token first, then if failed use the v2.
      const tokenUsed = recaptchaTokenV2 ?? token;
      // EDGE CASE: old recaptchaTokenV3 is for last fallback if both token are not loaded or missing
      const payload: LoginPayload = {
        email: email.trim(),
        password,
        recaptchaToken: tokenUsed ?? recapthcaTokenV3,
        recaptchaFallback: !!recaptchaTokenV2,
      };

      const data = await loginMutateAsync(payload);

      if (Cookies.get(credentialsEnv)) removeCredentials();
      Cookies.set(credentialsEnv, data.session.token, {
        domain: window.location.hostname === 'localhost' ? 'localhost' : '.easyship.com',
        secure: true,
        sameSite: 'none',
        partitioned: true,
        path: '/',
      });
      setTimeout(() => {
        window.location.replace(
          `https://app${IS_DEVELOPMENT ? `-${import.meta.env.VITE_ENV_NAME}` : ''}.easyship.com/dashboard`,
        );
      }, 500);
    } catch (err) {
      if (err.response?.data) {
        const error = parse(err.response.data);
        handleError(error);
      } else {
        toastError();
      }
    }
  };

  function removeCredentials(): void {
    const cookieDomain = window.location.hostname === 'localhost' ? 'localhost' : '.easyship.com';
    Cookies.remove(credentialsEnv, {
      secure: true,
      sameSite: 'none',
      partitioned: true,
      domain: cookieDomain,
      path: '/',
    });
  }

  const maskEmail = (email: string) => {
    const splitEmail = email.split('@');
    const username = splitEmail[0];
    let maskedEmail = email;
    if (username.length >= 3 && username.length <= 5) {
      maskedEmail =
        username.slice(0, 1) +
        '*'.repeat(username.length - 2) +
        username.slice(-1) +
        '@' +
        splitEmail[1];
    } else if (username.length >= 6 && username.length <= 9) {
      maskedEmail =
        username.slice(0, 2) +
        '*'.repeat(username.length - 4) +
        username.slice(-2) +
        '@' +
        splitEmail[1];
    } else if (username.length > 9) {
      maskedEmail = username.slice(0, 2) + '*'.repeat(3) + username.slice(-2) + '@' + splitEmail[1];
    }

    return maskedEmail;
  };

  const onRecaptchaV2Change = (token: string | null) => {
    if (token) {
      setRecaptchaTokenV2(token);
    }
  };

  return (
    <form className="flex flex-col gap-3" onSubmit={handleSubmit(onSubmit)}>
      <ControlledInput
        label="Email"
        control={control}
        name="email"
        rules={{
          required: { value: true, message: 'This field is required.' },
          pattern: { value: SIGNUP_LOGIN_EMAIL_REGEX, message: 'Please enter a valid email.' },
        }}
      />
      <ControlledInput
        label="Password"
        type="password"
        control={control}
        name="password"
        rules={{
          required: { value: true, message: 'This field is required.' },
        }}
      />
      <div>
        <Link className="text-blue-500" to="/auth/reset-password">
          Forgot your password?
        </Link>
      </div>
      {error && (
        <Alert severity="error" className="w-full">
          {error.message}
        </Alert>
      )}
      <Button
        onClick={handleSubmit(onSubmit)}
        color="primary"
        className="w-full disabled:bg-sky-500"
        type="submit"
        loading={isLoading || isFetchingRecaptcha}
        disabled={
          isLoading ||
          isFetchingRecaptcha ||
          ((error?.code === LoginErrorCode.INVALID_V3_TOKEN ||
            error?.code === LoginErrorCode.INVALID_V2_TOKEN) &&
            !recaptchaTokenV2)
        }
      >
        Login
      </Button>
      <p>
        No account yet?{' '}
        <a
          className="text-blue-500 hover:text-blue-700"
          href={`https://app${IS_DEVELOPMENT ? '-staging' : ''}.easyship.com/signup`}
          rel="noreferrer"
        >
          Get started for free
        </a>
      </p>
      {(error?.code === LoginErrorCode.INVALID_V3_TOKEN ||
        error?.code === LoginErrorCode.INVALID_V2_TOKEN) && (
        <ReCAPTCHA
          ref={recaptchaRef}
          className="flex items-center justify-center w-full mt-2"
          sitekey={import.meta.env.VITE_KEY_RECAPTCHA2}
          onChange={onRecaptchaV2Change}
        />
      )}
    </form>
  );
};
